<!--  -->
<template>
  <div>
    <el-card>
      <div style="margin-bottom: 20px;">
        <el-upload
          class="avatar-uploader"
          :action="action"
          :show-file-list="false"
          :on-success="
            (res, file) => {
              handleVehicleImg(res, file, 2)
            }
          "
          v-if="isEdit"
        >
          <img
            v-if="userInfo.headImage ? userInfo.headImage : require('@/assets/images/avatar.png')"
            :src="userInfo.headImage ? userInfo.headImage : require('@/assets/images/avatar.png')"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <img v-else :src="userInfo.headImage ? userInfo.headImage : require('@/assets/images/avatar.png')" alt="">
      </div>
      <el-descriptions>
        <el-descriptions-item label="昵称">
          <div v-if="!isEdit">
            {{ userInfo.name }}
          </div>
          <el-input v-else size="small" v-model="userInfo.name"></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions>
        <el-descriptions-item label="QQ">
          <div v-if="!isEdit">
            {{ userInfo.qq }}
          </div>
          <el-input v-else size="small" v-model="userInfo.qq"></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions>
        <el-descriptions-item label="微信">
          <div v-if="!isEdit">
            {{ userInfo.wechatNumber }}
          </div>
          <el-input
            v-else
            size="small"
            v-model="userInfo.wechatNumber"
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions>
        <el-descriptions-item label="手机号">
          <div v-if="!isEdit">
            {{ userInfo.phone }}
          </div>
          <el-input v-else size="small" v-model="userInfo.phone"></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <div class="btn">
        <el-button class="submit" @click="editInfo">{{
          isEdit ? '取消' : '修改'
        }}</el-button>
        <el-button v-if="isEdit" class="submit" @click="submit">
          提交
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getUser, updateUser } from '@/api/user'
import { upload } from '@/api/upload'

export default {
  data() {
    return {
      userInfo: {},
      isEdit: false,
      action: upload,
    }
  },
  created() {
    getUser().then((res) => {
      this.userInfo = res.data
      this.$store.commit('user/SET_AVATAR', res.data.headImage)
    })
  },
  methods: {
    editInfo() {
      this.isEdit = !this.isEdit
    },
    handleVehicleImg(res) {
      this.userInfo.headImage = res.data.url
    },
    submit() {
      updateUser(this.userInfo).then((res) => {
        this.isEdit = !this.isEdit
        getUser().then((res) => {
          this.userInfo = res.data
          sessionStorage.setItem('userInfo',JSON.stringify(res.data))
          this.$store.commit('user/SET_AVATAR', res.data.headImage)
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-descriptions-item__container {
  display: flex;
  align-items: center;
  span:nth-child(1) {
    width: 50px;
  }
}
img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.el-button {
  border: 1px solid #3c60bc;
  color: #3c60bc;
  width: 100px;
  margin-left: 25px;
  margin-bottom: 40px;
  margin-top: 20px;
}
.btn {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  .el-button {
    width: 150px;
  }
}
.el-descriptions {
  width: 300px;
  border-bottom: 1px solid #dddddd;
  color: #999;
  margin-bottom: 20px;
}
</style>
