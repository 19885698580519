import { uploadURL } from '@/utils/request.js'
import request from '@/utils/request.js'



export const upload = uploadURL + '/file/upload' 

// 上传图片
export function createStorage(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data
  })
}